.slick-slide {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.slick-center .slide-image {
  transform: scale(1.2);
 /* Enlarges the center slide */
  opacity: 1;
 /* Ensure full opacity */
  ;
}

.slide-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #E8E8E8;
  transform: scale(0.9);
}

.slide-image {
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 80%;
 /* Adjust width */
  height: auto;
  opacity: 0.7;
}

.slick-center {
  z-index: 10;
  transform: scale(1.1);
}

.slick-center .slide-item {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  box-shadow: 0px 2px 14px rgba(0,0,0,0.25);
}

.slick-center .slide-image {
  width: 100%;
 /* Make the center image bigger */
  opacity: 1;
}
