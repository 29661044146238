@font-face {
  font-family: almaz;
  src: url('/public/Font/Almaz_demo.ttf') format('truetype');
}

@font-face {
  font-family: cantry;
  src: url('/public/Font/Cantry.ttf') format('truetype');
}

@font-face {
  font-family: ethnocentric;
  src: url('/public/Font/Ethnocentric.otf') format('opentype');
}

@font-face {
  font-family: impact;
  src: url('/public/Font/Impact.ttf') format('truetype');
}

@font-face {
  font-family: strong;
  src: url('/public/Font/StrongYoung.ttf') format('truetype');
}

@font-face {
  font-family: quick;
  src: url('/public/Font/QuickPeachy.ttf') format('truetype');
}

@font-face {
  font-family: sifonn;
  src: url('/public/Font/SIFONN_PRO.otf') format('opentype');
}

@font-face {
  font-family: garamond;
  src: url('/public/Font/garamond.ttf') format('truetype') ;
}

@font-face {
  font-family: skyscrapers;
  src: url('/public/Font/Skyscapers.ttf') format('truetype') ;
}

@font-face {
  font-family: drover;
  src: url('/public/Font/droverhalf.ttf') format('truetype') ;
}

@font-face {
  font-family: lolapeluza;
  src: url('/public/Font/Lolapeluza.ttf') format('truetype');
}

@font-face {
  font-family: zenmaru;
  src: url('/public/Font/ZenMaruGothic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: zendots;
  src: url('/public/Font/ZenDots-Regular.ttf') format('truetype');
}

@font-face {
  font-family: protest;
  src: url('/public/Font/ProtestGuerrilla-Regular.ttf') format('truetype');
}

.protest {
  font-family: "protest",serif;
}

@font-face {
  font-family: rogbold;
  src: url('/public/Font/Rogbold-3llGM.otf') format('opentype') ;
}

@font-face {
  font-family: nohemi;
  src: url('/public/Font/nohemi.otf') format('opentype');
}

.nohemi {
  font-family: 'nohemi';
}

.rogbold {
  font-family: "rogbold",serif;
}

.drover {
  font-family: 'drover' ,serif;
}

.skyscrapers {
  font-family: 'skyscrapers',serif;
}

.garamond {
  font-family: 'garamond',serif;
}

.sifonn {
  font-family: 'sifonn',serif;
}

@font-face {
  font-family: sriracha;
  src: url('/public/Font/Sriracha.ttf') format('truetype');
}

.sriracha {
  font-family: "sriracha", serif;
}

.quick {
  font-family: 'quick',serif;
}

.strong {
  font-family: 'strong',serif;
}

.impact {
  font-family: 'impact',serif;
}

.ethnocentric {
  font-family: 'ethnocentric',serif;
}

.audiowide {
  font-family: "Audiowide", serif;
}

.sulphur {
  font-family: "Sulphur Point", serif;
}

.cantry {
  font-family: 'cantry',serif;
}

.almaz {
  font-family: "almaz",serif;
}

@font-face {
  font-family: urbanist;
  src: url('/public/Font/Urbanist.ttf') format('truetype');
}

.urbanist {
  font-family: "Urbanist", serif;
}

@font-face {
  font-family: rammetto;
  src: url('/public/Font/rammetto-one.regular.ttf') format('truetype');
}

.rammetto {
  font-family: "rammetto", serif;
}

@font-face {
  font-family: unboundedFont;
  src: url('/public/Font/Unbounded-Black.ttf') format('truetype');
}

.unbounded {
  font-family: "unboundedFont", serif;
}

.kaushan {
  font-family: "Kaushan Script", serif;
}

.timesnewroman {
  font-family: 'Times New Roman', Times, serif;
}

.akaya {
  font-family: "Akaya Kanadaka", serif;
}

.zenmaru {
  font-family: "zenmaru", serif;
}

.zendots {
  font-family: "zendots", serif;
}

.zhimang {
  font-family: "Zhi Mang Xing", serif;
}

.lolapeluza {
  font-family: "lolapeluza" serif;
}

@font-face {
  font-family: eloquia;
  src: url('/public/Font/EloquiaDisplay.otf') format('opentype');
}
/* 4865 */
.eloquia {
  font-family: 'eloquia' ,serif;
}

.vertical-text {
  writing-mode: vertical-rl;
  rotate: 180deg;
  text-wrap: nowrap;
}