.txtgrd {
    background: linear-gradient(104deg, rgba(13, 133, 250, 1) 0%, rgba(142, 0, 247, 1) 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.bgclr {
    background: rgba(115, 70, 254, 0.9);
}

.homebox {
    background: rgb(113, 107, 237);
    background: linear-gradient(277deg, rgba(113, 107, 237, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.homeboxtext {
    color: #2A107E;
}

.bannerbg {
    background: rgb(117, 73, 254);
    background: linear-gradient(210deg, rgba(117, 73, 254, 1) 0%, rgba(17, 15, 37, 1) 52%);
}

.vrl {
    writing-mode: vertical-rl;
}

.bgsec {
    background-image: url('../Assest/homesec2.webp');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.txtbgz {
    background: linear-gradient(180deg, rgba(152, 106, 233, 1) 0%, rgba(240, 243, 247, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    opacity: calc(0.5);
}

.curvebg {
    background-image: url('../Assest/curvebg.png');
    background-position: center;
    background-size: cover;
}

.curvebox {
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    background: rgb(152, 106, 233);
    background: linear-gradient(180deg, rgba(152, 106, 233, 0.6) 0%, rgba(240, 243, 247, 0.6) 100%);
}

.coursebg {
    background-image: url('../Assest/coursebg.png');
    background-position: center;
    background-size: cover;
}

.clippath1 {
    clip-path: polygon(21% 22%, 100% 0, 80% 80%, 0% 100%);
    border-radius: 20px;
    overflow: hidden;
}

.rotatex {
    transform: rotateZ(90deg);
    transform: rotate(104deg);
}

.eventbg {
    background-image: url('../Assest/Image.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.homeblogbg {
    background-image: url('../Assest/homeblogbg.png');
    background-position: bottom;
    background-size: cover;
}

.instructorbox {
    background: rgb(148, 0, 90);
    background: linear-gradient(260deg, rgba(148, 0, 90, 1) 0%, rgba(52, 22, 142, 1) 100%);
    /* background-image: url('../Assest/thumbprint.png'); */

}

.studentbox {
    background: rgb(0, 83, 154);
    background: linear-gradient(153deg, rgba(0, 83, 154, 1) 0%, rgba(38, 28, 73, 1) 100%);
}

.footerbg {
    background-image: url('../Assest/footerbg.webp');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}

.searchscroll::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    padding: 2px;
}

.searchscroll::-webkit-scrollbar-thumb {
    color: black;
    background-color: rgb(51, 65, 85);
    border-radius: 8px;
}


.fadein {
    animation: fadein 2s forwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
        transform: translateY(60px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}