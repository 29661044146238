.gradient-box {
  background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1)) padding-box,
    linear-gradient(to bottom, #8E01F7, #1D0191) border-box;
  border-radius: 10px;
  border: 4px solid transparent;
}

.btnbg2 {
  background: linear-gradient(to right, #8E01F7, #1D0191);
}

.tooltip {
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.bgblur {
  backdrop-filter: blur(10px);
}

.bannerclr1 {
  background: rgb(15, 126, 211);
  background: linear-gradient(93deg, rgba(15, 126, 211, 0.8) 0%, rgba(240, 8, 186, 0.8) 70%);
  animation: moveDiagonal 9s ease-in infinite;
  background-size: 250% 100%;
  filter: blur(1300px);
  /* Adjusted blur for visibility */
  position: absolute;
  /* Needed for the element's position to be animatable */
  top: 0;
  left: 0;
}

@keyframes moveDiagonal {
  0% {
    transform: translate(-20vw, -40vh); /* Starting position */
  }
  50% {
    transform: translate(50vw, 50vh); /* Move diagonally to bottom-right */
  }
  100% {
    transform: translate(-20vw, -40vh); /* Starting position */
  }
}

.bannerclr2 {
  background-color: #8f87f9;
  animation: moveDiagonal2 5s ease-in-out infinite;
  /* background-size: 250% 100%; */
  backdrop-filter: blur(20px);
  position: absolute; /* Needed for the element's position to be animatable */
  ;
}

@keyframes moveDiagonal2 {
  100% {
    /* transform: translate(100%, 100%);  */
    bottom: 50%;
    /* Initial position */
    right: 40%;
    /* Initial position */
    ;
  }
  
  0% {
    /* transform: translate(0, 0);  */
    bottom: -50%;
    /* Initial position */
    right: -40%;
    /* Initial position */
    ;
  }
}

.devopbanner {
  background-image: url('../Assest/devopBgPattern-min.png');
  background-position: center;
  background-size: cover;
}

.list-circle {
  list-style: circle;
}
/* Hide spin buttons in WebKit browsers (Chrome, Safari, Edge) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin buttons in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.heartbeat {
  animation: heartbeat 1s alternate-reverse infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.9);
  }
  
  100% {
    transform: scale(1);
  }
}

