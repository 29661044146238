@tailwind base;

@tailwind components;

@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
}

.navbg {
  background-color: #242146;
}

.btnbg {
  background-color: #E83B5F;
}

.opensan {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.blurbg {
  filter: blur(10px);
}

.slick-slide > div {
  margin: 0px 10px;
}

.custom-margin-all > div {
  margin: 1.5rem 0.5rem;
}

@media (min-width:420px) {
  .custom-margin-all>div {
    margin: 2rem 6.5rem;
  }
  
  .linespacing {
    line-height: 50px;
  }
}

@media (max-width :1030px) {
  .slick-slide>div {
    margin: 0px 10px;
  }
}

.scrollbar::-webkit-scrollbar {
  opacity: 0;
}

.fadein2 {
  animation: fadein2 0.5s linear forwards;
}

@keyframes fadein2 {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}