.coursecard {
    background: rgb(245, 207, 105);
    background: linear-gradient(180deg, rgba(245, 207, 105, 1) 0%, rgba(245, 170, 86, 1) 27%, rgba(245, 134, 68, 1) 100%);
}

.journeycard {
    background: rgb(87, 47, 158);
    background: linear-gradient(4deg, rgba(87, 47, 158, 1) 0%, rgba(97, 32, 220, 1) 100%);
}

.bulbbg {
    background: rgb(133, 71, 246);
    background: linear-gradient(173deg, rgba(133, 71, 246, 1) 0%, rgba(188, 152, 255, 1) 100%);
}

.star {
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.reviewshadow {
    box-shadow: 12px -3px 1px 9px #7346FE;
}

.othercoursesbg {
    background-image: url('../Assest/courseOtherbg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}

.contentbg {
    background: rgb(56, 29, 219);
    background: linear-gradient(180deg, rgba(56, 29, 219, 0.15175586347820376) 0%, rgba(56, 29, 219, 0) 100%);

}

.coursetestimonialbg {
    background-image: url('../Assest/coursetestimonialgb.png');
    background-size: cover;
}

.hexagon {
    background-image: url('../Assest/Polygon1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.contactcoursebg {
    background-image: url('../Assest/coursecontactbg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.inputbg {
    background: rgb(255, 255, 255);
    background: linear-gradient(76deg, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
}

.inputbg::placeholder {
    color: white;
}

.inputbg::-webkit-inner-spin-button {
    display: none;
}

.hexagon1 {
    background-image: url('../Assest/Polygon2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.pagination {
    list-style: none;
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    gap: 10px;
    margin-top: 5rem;

}

.bordergrd {
    border: 2px solid transparent;
    border-right: 0px;
    border-left: 0px;

    border-image: linear-gradient(110deg, rgba(144, 156, 146, 0) 0%, rgba(144, 156, 146, 1) 50%, rgba(144, 156, 146, 0) 100%);
    border-image-slice: 1;
}

.pagination .page-num {
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    text-decoration: none;
    color: black;
    font-weight: 400;
    /* transition: 1s; */
    border-radius: 20px;
    background-color: white;
    border-radius: 4px;
    border: 2px solid rgb(203, 202, 202);
}

.sidescroll::-webkit-scrollbar {
    width: 10px;
}

.sidescroll::-webkit-scrollbar-thumb {
    background-color: rgb(175, 173, 178);
    border-radius: 10px;
}

.pagination .page-num:hover {
    background-color: #5751E1;
    color: white;
    transition: 0.8s;
    border: 2px solid #5751E1;


}

.pagination .active {
    background-color: #5751E1;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    border: 2px solid #5751E1;
}

.pagination .next {
    display: none;
}

.pagination .previous {
    display: none;
}

.slick-current .slider-item {
    margin-top: -50px;
    z-index: 10;
    transition: 1s ease-in-out;
    filter: drop-shadow(3px 2px 10px black);
    animation: zoomin 2s linear forwards;
}

@keyframes zoomin {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.09)
    }

}

.slideInRight {
    animation: slideInRight 0.7s linear forwards;
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slideInleft {
    animation: slideInleft 0.7s linear forwards;
}

@keyframes slideInleft {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}