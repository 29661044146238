.grd1 {
  background: rgb(96, 14, 82);
  background: linear-gradient(232deg, rgba(96, 14, 82, 1) 0%, rgba(17, 15, 37, 1) 100%);
}

.grd1 .icon {
  color: rgb(223, 44, 196);
}

.grd1 .icon:hover {
  color: #9FBBFF;
}

.grd10 {
  background: rgb(0, 0, 0);
  background: linear-gradient(96deg, rgba(0, 0, 0, 1) 0%, rgba(30, 44, 106, 1) 100%);
}

.grd10 .icon {
  color: rgb(91, 103, 157);
}

.grd10 .icon:hover {
  color: rgb(201, 239, 243);
}

.grd2 {
  background: rgb(0, 0, 0);
  background: linear-gradient(96deg, rgba(0, 0, 0, 1) 0%, rgba(30, 44, 106, 1) 100%);
}

.grd2 .icon {
  color: rgb(91, 103, 157);
}

.grd2 .icon:hover {
  color: rgb(201, 239, 243);
}

.grd4 {
  background: rgb(20, 32, 39);
  background: linear-gradient(96deg, rgba(20, 32, 39, 1) 0%, rgba(58, 102, 136, 1) 100%);
}

.grd4 .icon {
  color: rgba(20, 32, 39, 1);
}

.grd4 .icon:hover {
  color: rgb(150, 182, 206);
}

.grd5 {
  background: rgb(20, 32, 39);
  background: linear-gradient(96deg, rgba(20, 32, 39, 1) 0%, rgba(58, 102, 136, 1) 100%);
}

.grd5 .icon {
  color: rgba(20, 32, 39, 1);
}

.grd5 .icon:hover {
  color: rgb(150, 182, 206);
}

.grd6 {
  background: rgb(36, 157, 130);
  background: linear-gradient(96deg, rgba(36, 157, 130, 1) 0%, rgba(37, 33, 69, 1) 100%);
}

.grd6 .icon {
  color: rgb(76, 140, 177);
}

.grd6 .icon:hover {
  color: rgb(128, 189, 235);
}

.grd7 {
  background: rgb(36, 157, 130);
  background: linear-gradient(96deg, rgba(36, 157, 130, 1) 0%, rgba(37, 33, 69, 1) 100%);
}

.grd7 .icon {
  color: rgb(76, 140, 177);
}

.grd7 .icon:hover {
  color: rgb(128, 189, 235);
}

.grd8 {
  background: rgb(96, 14, 82);
  background: linear-gradient(232deg, rgba(96, 14, 82, 1) 0%, rgba(17, 15, 37, 1) 100%);
}

.grd8 .icon {
  color: rgb(223, 44, 196);
}

.grd8 .icon:hover {
  color: #9FBBFF;
}

.grd9 {
  background: rgb(36, 157, 130);
  background: linear-gradient(96deg, rgba(36, 157, 130, 1) 0%, rgba(37, 33, 69, 1) 100%);
}

.grd9 .icon {
  color: rgb(76, 140, 177);
}

.grd9 .icon:hover {
  color: rgb(128, 189, 235);
}

.grd11 {
  background: rgb(20, 23, 54);
  background: linear-gradient(96deg, rgba(20, 23, 54, 1) 0%, rgba(33, 54, 81, 1) 100%);
}

.grd11 .icon {
  color: rgb(76, 140, 177);
}

.grd11 .icon:hover {
  color: rgb(128, 189, 235);
}

.grd12 {
  background: rgb(139, 17, 59);
  background: linear-gradient(96deg, rgba(139, 17, 59, 1) 0%, rgba(251, 96, 147, 1) 100%);
}

.grd12 .icon {
  color: rgb(134, 41, 74);
}

.grd12 .icon:hover {
  color: rgb(248, 176, 202);
}

.grd13 {
  background: rgb(21, 29, 45);
  background: linear-gradient(96deg, rgb(23, 31, 49) 0%, rgba(42, 82, 137, 1) 100%);
}

.grd13 .icon {
  color: rgb(29, 37, 56);
}

.grd13 .icon:hover {
  color: rgb(248, 176, 202);
}

.grd14 {
  background: rgb(26, 5, 12);
  background: linear-gradient(96deg, rgba(26, 5, 12) 0%, rgba(251, 96, 147, 1) 100%);
}

.grd14 .icon {
  color: rgb(134, 41, 74);
}

.grd14 .icon:hover {
  color: rgb(248, 176, 202);
}

.grd15 {
  background: rgb(0, 0, 0);
  background: linear-gradient(96deg, rgba(0, 0, 0, 1) 0%, rgba(209, 254, 133, 1) 100%);
}

.grd15 .icon {
  color: rgb(55, 83, 6);
}

.grd15 .icon:hover {
  color: rgb(61, 95, 6);
}

.grd16 {
  background: rgb(76, 77, 173);
  background: linear-gradient(96deg, rgba(76, 77, 173, 1) 0%, rgba(177, 202, 227, 1) 100%);
}

.grd16 .icon {
  color: rgba(76, 77, 173, 1);
}

.grd16 .icon:hover {
  color: rgb(122, 124, 243);
}

.grd17 {
  background: rgb(0, 0, 0);
  background: linear-gradient(96deg, rgba(0, 0, 0, 1) 0%, rgba(98, 142, 71, 1) 100%);
}

.grd17 .icon {
  color: rgb(39, 82, 11);
}

.grd17 .icon:hover {
  color: rgb(188, 230, 162);
}

.grd18 {
  background: rgb(76, 77, 173);
  background: linear-gradient(96deg, rgba(76, 77, 173, 1) 0%, rgba(177, 202, 227, 1) 100%);
}

.grd18 .icon {
  color: rgba(76, 77, 173, 1);
}

.grd18 .icon:hover {
  color: rgb(122, 124, 243);
}

.grd19 {
  background: rgb(78, 36, 43);
  background: linear-gradient(96deg, rgb(78, 36, 43) 0%, rgba(159, 75, 86, 1) 100%);
}

.grd19 .icon {
  color: rgb(94, 17, 30);
}

.grd19 .icon:hover {
  color: rgb(243, 132, 151);
}

.grd20 {
  background: rgb(22, 134, 138);
  background: linear-gradient(96deg, rgba(22, 134, 138, 1) 0%, rgb(101, 182, 120) 100%);
}

.grd20 .icon {
  color: rgb(15, 88, 90);
}

.grd20 .icon:hover {
  color: rgb(22, 105, 108);
}

.grd21 {
  background: rgb(65, 80, 175);
  background: linear-gradient(96deg, rgba(65, 80, 175, 1) 0%, rgba(89, 176, 222, 1) 100%);
}

.grd21 .icon {
  color: rgba(65, 80, 175, 1);
}

.grd21 .icon:hover {
  color: rgb(85, 85, 87);
}

.grd22 {
  background: rgb(96, 14, 82);
  background: linear-gradient(232deg, rgba(96, 14, 82, 1) 0%, rgba(17, 15, 37, 1) 100%);
}

.grd22 .icon {
  color: rgb(223, 44, 196);
}

.grd22 .icon:hover {
  color: #9FBBFF;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.bggrdnew {
  background: linear-gradient(45deg, 
    #f636c7 20%,
    /* #2964ff 30% */

    #27d9ff 50%,
    #2964ff 100%
    
    );
  background-size: 200% 200%;
 /* Increase size for a smoother diagonal transition */
  animation: gradientMove 10s linear infinite;
}
